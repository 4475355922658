import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import assignSubUnitDetails from './assignSubUnitDetails';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import downlaodFiles from '../leaseAssignSubUnit/assignSubUnitDetails/downloadFiles';
export default {
  name: 'LeaseAssignSubUnit',
  watch: {
    currentPage: function() {
      this.getLeaseSubUnitList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseSubUnitList();
    },
    downloadCurrentPage: function() {
      this.getUploadedDocDtl();
    },
    downloadPerPage: function() {
      this.downloadCurrentPage = 1;
      this.getUploadedDocDtl();
    }
  },
  components: {
    assignSubUnitDetails,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    downlaodFiles
  },
  data() {
    return {
      leasePrj: {
        value: null,
        text: null
      },
      leaseTower: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      leaseUnit: null,
      active: true,
      unitDisplayName: null,
      unsubscribe: null,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      leasePrjUnitId: null,
      unitName: null,
      selectedGridData: null,
      historyType: null,
      showHistory: false,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      downloadCurrentPage: 1,
      downloadPerPage: commonHelper.perPageRecord,
      totalRows: null,
      showHistoryBtn: false,
      showOpenKmModal: false,
      currentPage: 1,
      leaseUnitRowDetails: null,
      leaseSubUnitData: [],
      leaseUnitSubUnitDataFields: [
        // {
        //   key: 'active_radio',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'primary',
        //   class: 'stickyColumn'
        // },      
        {
          key: 'lease_prj_id',
          class: 'd-none'
        },
        {
          key: 'lease_prj_name',
          label: 'Project'
        },
        {
          key: 'lease_prj_unit_id',
          class: 'd-none'
        },
        {
          key: 'unit_display_name',
          class: 'd-none'
        },
        {
          key: 'unit_name',
          label: 'Lease Office'
        },
        {
          key: 'billable_area',
          label: 'Lease Billable Area'
        },
        {
          key: 'billable_area_uom_vset',
          class: 'd-none'
        },
        {
          key: 'billable_area_uom_vset_meaning',
          label: 'Lease Billable UOM'
        },
        {
          key: 'area_mapped',
          label: 'FMS Billable Area'
        },
        {
          key: 'fms_billable_area_uom_vset',
          class: 'd-none'
        },
        {
          key: 'fms_billable_area_uom_vset_meaning',
          label: 'FMS Billable UOM'
        },
        { 
          key: 'approval_status'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      showDownloadModal: false, 
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseAssignSubUnit/getLeaseSubUnitList',
            'lease-unit-sub-unit-mapping',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getLeaseSubUnitList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        lease_project_id: this.leasePrj.value,
        unit_name: this.leaseUnit,
        unit_display_name: this.unitDisplayName,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('leaseAssignSubUnit/getLeaseSubUnitList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseSubUnitData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.leasePrj = {
        value: null,
        text: null

      };
      this.leaseUnit = null;
      this.unitDisplayName = null;
      this.active = true;
      this.parent_value_set_id = null;
      this.leaseSubUnitData = [];
      this.totalRows = null;
      this.showHistoryBtn = false;
      this.currentPage = 1;
    },
    rowSelected(item) {
      this.leaseUnitRowDetails = item;
      this.leasePrjUnitId = item.lease_prj_unit_id;
      this.showHideAddLeaseModal(true);
    },
    showHideAddLeaseModal(flag) {
      this.showAddLeaseUnitModal = flag;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    selectedApproval(item) {
      this.leaseUnitRowDetails = item;
      this.leasePrjUnitId = item.lease_prj_unit_id;
      // this.showHideAddLeaseModal(true);
      this.showHistoryBtn = true;
    },
    checkOpenKmPasswordSaved(modalType) {
      // to check password save or not
      this.modalType = modalType;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            if (this.modalType === 'DOWNLOAD') {
              this.downloadFiles(true);
            } else {
              this.selectedGridData = {
                ...this.leaseUnitRowDetails,
                nfa_cat_vset: null,
                nfa_cat: null,
                nfa_sub_cat_vset: null,
                nfa_sub_cat: null
              };
              this.showHideHistory(true, 'uploadDocument');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    
    showHideOpenKmModal(flag) {
      // to open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      // to set token in km
      this.showHideOpenKmModal(false);
      if (this.modalType === 'DOWNLOAD') {
        this.downloadFiles(true);
      } else {
        this.openkmAuthToken = token;
        this.selectedGridData = {
          ...this.leaseUnitRowDetails,
          nfa_cat_vset: null,
          nfa_cat: null,
          nfa_sub_cat_vset: null,
          nfa_sub_cat: null
        };
        this.showHideHistory(true, 'uploadDocument');
      }
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      // this.getUploadedDocDtl();
    },
    // getUploadedDocDtl() {
    //   const payload = {
    //     _page: this.downloadCurrentPage - 1,
    //     _limit: this.downloadPerUploadPage,
    //     menu_id: store.state.shared.menuId
    //       ? store.state.shared.menuId
    //       : this.urlSummaryData.wf_menu_id,
    //     sub_menu_id: 0,
    //     record_id: this.nfaHdrId
    //       ? this.nfaHdrId
    //       : this.urlSummaryData.nfa_hdr_id
    //   };
    //   this.$store
    //     .dispatch('intWorkflow/getOpenKmDocDtl', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         if (response.data.data.page.length > 0) {
    //           const docList = response.data.data.page;
    //           this.downloadItems = docList.map(data => {
    //             data.file_size = this.formatBytes(data.file_size);
    //             return data;
    //           });
    //         }
    //         this.totalUploadRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
      switch(vsetCode){
        case 'LEASE_PROJECT_LIST':
          this.leasePrj = {
            text: null,
            value: null
          }
          break;
      }

    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
